// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navbar Styles */
.navbar {
    background-color: #333; /* Dark gray background color */
    padding: 10px; /* Add padding for spacing */
  }
  
  /* Navigation Links */
  .nav-links {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: flex-start; /* Left-align the links */
  }
  
  /* Individual Navigation Link */
  .nav-link {
    margin-right: 20px; /* Add margin for spacing between links */
    color: #fff; /* White text color */
    text-decoration: none;
    cursor: pointer;
  }

  #logo {
    margin-right: 20px; /* Add margin for spacing between links */
    color: #fff; /* White text color */
    text-decoration: none;
    padding-right: 25px;
    font-weight:bolder;
    border-right: 2px solid #dbdbdb;
  }
  
  /* Style on hover (optional) */
  .nav-link:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,sBAAsB,EAAE,+BAA+B;IACvD,aAAa,EAAE,4BAA4B;EAC7C;;EAEA,qBAAqB;EACrB;IACE,qBAAqB;IACrB,UAAU;IACV,aAAa;IACb,2BAA2B,EAAE,yBAAyB;EACxD;;EAEA,+BAA+B;EAC/B;IACE,kBAAkB,EAAE,yCAAyC;IAC7D,WAAW,EAAE,qBAAqB;IAClC,qBAAqB;IACrB,eAAe;EACjB;;EAEA;IACE,kBAAkB,EAAE,yCAAyC;IAC7D,WAAW,EAAE,qBAAqB;IAClC,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,+BAA+B;EACjC;;EAEA,8BAA8B;EAC9B;IACE,0BAA0B;EAC5B","sourcesContent":["/* Navbar Styles */\n.navbar {\n    background-color: #333; /* Dark gray background color */\n    padding: 10px; /* Add padding for spacing */\n  }\n  \n  /* Navigation Links */\n  .nav-links {\n    list-style-type: none;\n    padding: 0;\n    display: flex;\n    justify-content: flex-start; /* Left-align the links */\n  }\n  \n  /* Individual Navigation Link */\n  .nav-link {\n    margin-right: 20px; /* Add margin for spacing between links */\n    color: #fff; /* White text color */\n    text-decoration: none;\n    cursor: pointer;\n  }\n\n  #logo {\n    margin-right: 20px; /* Add margin for spacing between links */\n    color: #fff; /* White text color */\n    text-decoration: none;\n    padding-right: 25px;\n    font-weight:bolder;\n    border-right: 2px solid #dbdbdb;\n  }\n  \n  /* Style on hover (optional) */\n  .nav-link:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
