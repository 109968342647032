// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header Styles */
.header {
    text-align: center;
    color: #333; /* White text color */
    padding: 20px 0; /* Add padding for spacing */
  }
  
  /* Main Heading Styles */
  .main-heading {
    font-size: 24px; /* Adjust the font size as needed */
    margin: 0; /* Remove default margin */
  }
  
  /* Sub Heading Styles */
  .sub-heading {
    font-size: 18px; /* Adjust the font size as needed */
    margin: 0; /* Remove default margin */
  }`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,kBAAkB;IAClB,WAAW,EAAE,qBAAqB;IAClC,eAAe,EAAE,4BAA4B;EAC/C;;EAEA,wBAAwB;EACxB;IACE,eAAe,EAAE,mCAAmC;IACpD,SAAS,EAAE,0BAA0B;EACvC;;EAEA,uBAAuB;EACvB;IACE,eAAe,EAAE,mCAAmC;IACpD,SAAS,EAAE,0BAA0B;EACvC","sourcesContent":["/* Header Styles */\n.header {\n    text-align: center;\n    color: #333; /* White text color */\n    padding: 20px 0; /* Add padding for spacing */\n  }\n  \n  /* Main Heading Styles */\n  .main-heading {\n    font-size: 24px; /* Adjust the font size as needed */\n    margin: 0; /* Remove default margin */\n  }\n  \n  /* Sub Heading Styles */\n  .sub-heading {\n    font-size: 18px; /* Adjust the font size as needed */\n    margin: 0; /* Remove default margin */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
